@import "/src/app/styles/for-components";

.cart-item{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    width: 95%;
    margin: auto;
    margin-bottom: 2rem;
    padding: 1rem 1rem 0 1rem;
    box-shadow: $shadow;
    border-radius: $border-big;
    background-color: $white;
    @include break-up(desktop){
        flex-flow: row wrap;
        width: 60%;
        margin: 0;
        gap: 0;
    }
    .item-title{
        width: 100%;
        h2{
            margin-bottom: 1rem;
            @include font-basic;
            font-size: 30px;
        }
    }
    .item-img{
        width: 100%;
        contain: paint;
        @include break-up(desktop){
            width: 40%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $border-small;
        }
    }
    .item-price{
        width: 100%;
        margin-top: 1rem;
        @include break-up(desktop){
            width: 60%;
            margin: 0;
            padding-left: 2rem;
        }
        h3{
            padding-bottom: 0.5rem;
            border-bottom: $border;
        }
        >div{
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        .total {
            padding-top: 0.5rem;
            margin-top: 1rem;
            border-top: 1px solid lightGrey;
            font-weight: bold;
        }
        .discount{
            color: $green;
        }
    }
    .item-details{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 1rem;
        padding: 1rem 0;
        border-top: 1px dashed $light-grey;
        svg{
            color: $red;
            margin-right: 1rem
        }
        p{
            width: 60%;
        }
    }
}

.skeleton{
    & .item-title{
        margin-bottom: 1rem;
    }
    & .item-img-skeleton{
        width: 86vw;
        height: 80vw;
        background-color: $light-grey;
        border-radius: $border-big;
        overflow: hidden;
        @include break-up(desktop){
            width: 150px;
            height: 150px;
        }
    }
}


