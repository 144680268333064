@import "/src/app/styles/for-components";

.card{
    position: relative;
    width: 100%;
    height: fit-content;
    min-width: 300px;
    max-width: 406px;
    padding: 0.7rem;
    margin: 0.5rem 0;
    background-color: $white;
    box-shadow: $shadow;
    transition: box-shadow 0.25s ease;   
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px;
        transform: translateY(-5px);
        transition: box-shadow 0.25s ease, transform 0.25s ease;
    }
    >a{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .card-chips{
        position: absolute;
        top: 1rem;
        left: 1rem;
        display: flex;
        gap: 0.5rem;
        z-index: 1;
        & .card-chip{
            padding: 0.5rem;
            &.novelty{
                // background-color: $green ;
                color: var(--white);
            }
            &.promoted{
                // background-color: var(--yellow) ;
                color: var(--black) ;
            }
            &.isSold{
                // background-color: var(--red);
                color: var(--white) ;
            }
        }
    }
    .card-carousel{
        width: 100%;
        background-color: $light-grey;
        overflow: hidden;
        height: 205px;
        >.emptyImg{
            position: relative;
            width: 100%;
            aspect-ratio: 1 / 0.6;
            background-color: $light-grey;
            display:flex;
            flex-flow: column wrap;
            justify-content:center;
            align-items:center;
            gap: 1rem;
            color: $white;
            text-align: center;
            & svg{
                height: 30%;
            }
        }
        .splide__slide img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        .splide__arrow{
            border-radius: 0px;
            background-color: $white;
            opacity: 1;
            &:hover{
                background-color: $red;
                svg path{
                    fill: $white;
                }
            }
            svg{
                width: 0.8rem;
                height: 0.8rem;
            }
        }
        .splide__arrow--prev{
            left: 0.5em;
        }
        .splide__arrow--next{
            right: 0.5em;
        }
    }
    .card-info{
        padding-top: 1rem;
        display: flex;
        flex-flow: column nowrap;
        gap: 0;
    }
    .card-title{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 0.5rem 0 1rem 0;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
            -ms-flex-align: baseline;
                align-items: baseline;
    }
    .card-location{
        font-size: 10px;
        text-transform: uppercase;
        text-align: left;
        letter-spacing: 1px;
        svg{
            height: 12px;
            margin-right: 1px;
        }
    }
    .card-equipment{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        row-gap: 1rem;
        gap: 1rem;
        min-height: 18.5px;
        margin-bottom: 1rem;
        .card-icone{
            font-size: 14px;
            svg{
                fill: $black;
                margin-right: 0.5rem
            }
        }
    }
    .card-price{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        row-gap: 1rem;
        text-align: left;
        div{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            & .period-span{
                font-style: italic;
                opacity: 0.8;
                font-size: 12px;
                color: var(--blue);
                font-weight: 300;
            }
        }
    }
    .chip{
        position: absolute;
        top: 1.5rem;
        left: 1.2rem;
        font-weight: 500;
        box-shadow: $shadow;
        z-index: 1;
        span{
            display: inline;
            font-size: 14px;
            margin-right: 0.5rem;
            padding: 0.4rem 0.6rem;
            border-radius: $border-round;
            &.recommended{
                background-color: $white;
                color: $red;
            }
            &.novelty{
                background-color: $red;
                color: $white;
            }
            &.promoted{
                background-color: $white;
                color: $red;
            }
        }
    }
}

.react-loading-skeleton.skeleton-card-title{
    height:1rem ;
    margin-bottom: 0.7rem;
}
