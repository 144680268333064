@import "/src/app/styles/for-components";

.payment-result{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    padding: 2rem 1rem;
    box-shadow: $shadow;
    border-radius: $border-big;
    margin-bottom: 4rem;
    text-align: center;
    background-color: $white;
    @include break-up(desktop){
        width: 50%;
        padding: 2rem;
    }
    >div:first-child{
        display:flex;
        justify-content:center;
        align-items:center;
        width: 100px;
        height: 100px;
        border-radius: 200px;
        border: 4px solid $red;
        color: $red;
        margin-bottom: 2rem;
        &.succeeded{
            border: 4px solid $green;
            color: $green;
        }
        svg{
            height: 50%;
            width: 50%;
        }

    }
    p{
        margin-top: 2rem;
    }
    a{
        margin-top: 2rem;
    }
}
