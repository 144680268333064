@import "/src/app/styles/for-components";

.subInput{
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    transform: translateY(-10px);
    grid-template-columns: 1fr;
    grid-template-rows: 3fr;
    padding: 0.5rem;
    background-color: $white;
    color: $black;
    opacity: 0;
    visibility: hidden;
    @include break-up(desktop){
        top: 126%;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        padding: 1rem 2rem;
        box-shadow: $shadow;
        border: $border;
        transition: all 0.25s ease-in-out;
        cursor: default;
    }
    @include break-down(big-desktop){
        &.type{
            left: 0;
            transform: inherit;
            .subInput-arrow,.subInput-hideArrow{
                left: 10%;
            }
        }
    }
    label,p{
        font-size:0.888em;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;
    }
    .subInput-title{
        white-space: nowrap ;
        span{
            font-weight: normal;
            display: block;
            font-style: italic;
        }
    }
    
    .subInput-arrow{
        display: none;
        @include break-up(desktop){
            display: block;
            position: absolute;
            top: -0.6rem;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            width: 1.302rem;
            height: 1.302rem;
            background-color: $white;
            box-shadow: $shadow;
            border-radius: 4px;
            border: $border;
            z-index: 1;
        }
    }
    
    .subInput-hideArrow{
        display: none;
        @include break-up(desktop){
            display: block;        
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 4rem;
            height: 1.302rem;
            z-index: 2;
            background-color: $white;
        }
    }
    
    .subInput-inputs,.subInput-validation{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;
        z-index: 2;
    }
    
    .subInput-inputs{
        gap: 1rem;
        justify-content: flex-start;
        padding: 1.302rem 0 1.651rem 0 ;
        border-bottom:  $border;
        @include break-up(desktop){
            justify-content: center;
        }
    }
    
    .subInput-validation{
        justify-content: space-between;
        padding-top: 0.621rem;
        .btn-red{
            display: none;
            @include break-up(desktop){
                display: block;
            }
        }
    }
    
    .articlePrice{
        min-width: 3rem;
    }
    
    .articlePrice-container{
        display: flex;
        flex-flow: row wrap;
    }
}



