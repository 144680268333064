@import "/src/app/styles/for-components";

.searchModule {
    max-width: 1280px;
    margin: auto;
    padding: 0.5rem $layout-padding;
    transition: top ease 0.5s;
    @include break-up(desktop){            
        &.up{
            position: sticky;
            top: 0;
            left: 0;
            transform: translateX(0);
        }
    }
    &.down{
        position: absolute;
        top: 75svh;
        left: 0;
        @include break-up(desktop){
            position: fixed;
            top: 85svh;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &.sticky{
        position: sticky;
        top: 0;
        background-color: $background;
    }
    &.hide{
        display: none;
    }
    .container{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem;
        margin: auto;
        background-color: $white;
        border-radius: 20px;
        box-shadow: $shadow;
        @include break-up(desktop){
            flex-flow: row nowrap;
            padding: 0.5rem 0.6rem;
            >div:nth-child(n+2):nth-child(-n+6){
                border-left: $border;
            }
        }
    }
    .search-type-button{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        min-width: 7rem;
        cursor: pointer;
        @include break-up(desktop){
            width: 395px;
        }
        >div, >button{
            width: 50%;
        }
        #location{
            border-right: $border;
            @include break-up(desktop){
                border-right: none;
                min-width: 192px;
            }
            &.show>.subInput{
                opacity: 1;
                visibility: visible;
                transition: all 0.5s ease-in-out;
                z-index: 12;
            }
            .subInput-inputs{
                flex-direction: column;
                align-items: baseline;
                gap: 0.5rem;
            }
        }
    }
    .search-module-button{
        position: relative;
        display: flex;
        justify-content: center;
        gap: .5rem;
        width: 50%;
        padding: 1.6rem 0.5rem;
        white-space: nowrap;
        cursor: pointer;
        &.hide{
            display: none;
        }
        @include break-up(desktop){
            width: fit-content;
            min-width: 7rem;
            padding: 1rem;
            &:hover>svg{
                transform: rotate(180deg);
                transition: transform 0.5s ease-in-out;
                fill: $black;
            }
            &:hover .subInput{
                opacity: 1;
                visibility: visible;
                transition: all  0.5s ease-in-out;    
            }
            &.red:hover{
                background-color: $white;
                color: $black;
                fill: $black;
            }
        }
        &.red{
            font-weight: 500;
            &:before{
                content: url("../../../assets/svg/trait-rouge-grand.svg");
                position:absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-46%);
                width: 95%;
                max-width: 180px;
                height: auto;
                @include break-up(desktop){
                    height: 120%;
                    width: 100%;
                    min-width: 180px;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
            svg{
                fill: $black;
            }
            &:active{
                color:$red;
            }
        }
        .persons{
            min-width: 155px;
        }
        &.search{
            width: 100%;
            border-top: $border;
            justify-content: flex-start;
            svg{
                height: 18px;
                width: 30px;

            }
        }
        >svg{
            position: relative;
            width: 12px;
            transform-origin: center;
            transition: transform 0.5s ease-in-out;
        }
        &:active{
            color: $red;
        }
    }
    .subInput.objectType .subInput-inputs{
        display: grid;
        grid-template-columns: 50% 50%;
    }
}


.filter-module{
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    grid-template-rows: 60px auto 70px;
    width: 100vw;
    height: 100svh;
    background-color: $background;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50px);
    transition: opacity, visibility, transform 0.5s ease;
    z-index: -1;
    overflow: clip;
    &.show{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: opacity, visibility, transform 0.5s ease;
        z-index: 11;
    }
    .filter-module-header{
        position: sticky;
        top: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 1rem;
        background-color: $white;
        box-shadow: $shadow;
        z-index: 12;
        >*{
            align-self: center;
        }
        >h3{
            grid-column: 2 / 3;
            justify-self: center;
            margin-bottom: 0;
            white-space: nowrap;
        }
        >svg{
            grid-column: 3 / 4;
            justify-self: flex-end;
            height: 100%;
        }
    }
    .filter-module-inputs{
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 3px;
        }
            
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        
        &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
            background-color: $red;
        }
    }
    .search-module-button{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        width: 95%;
        margin: 0.8rem auto;
        padding: .5rem;
        box-shadow: $shadow;
        background-color: $white;
        border-radius: $border-medium;
        >div:nth-child(1){
            display: grid;
            grid-template-columns: 55% 40% 5%;
            align-items: center;
            padding: 1rem 0.5rem;
            background-color: $white;
            z-index: 12;
            svg{
                grid-column: 3/4;
                height: 1em;
                width: 1em;
            }
        }
        &.hide{
            display: none;
        }
        &.show{
            >.subInput{
                position: relative;
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: 
                transform 0.5s ease-in-out,
                visibility 0.15s ease-in-out,
                opacity 0.15s ease-in-out;
                z-index: 11;
            }
            .chevron{
                transform: rotate(180deg);
            }
        }
        &.calendar{
            .subInput-inputs{
                justify-content: center;
            }
        }
    }
    .filter-module-buttons{
        display: flex;
        justify-content: space-between;
        width: 95%;
        margin: 0.8rem auto;
    }
    .equipment{
        display: flex;
        gap: 1rem;
        padding: 1rem 0;
        label{
            display: flex;
            gap: 0.5rem;
        }
        input[type=checkbox]{
            border-color: $black;
            &:checked{
                border-color: $red;
                &::before{
                    background-color: $red;
                }
            }
        }
    }
    .subInput.objectType .subInput-inputs{
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 0;
        row-gap: 1rem;
    }
}