@import "/src/app/styles/for-components";

.connexion-form{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    width: 95%;
    margin: auto;
    margin-bottom: 4rem;
    padding: 2rem 1rem 1rem 1rem;
    background-color: $white;
    border-radius: $border-big;
    box-shadow: $shadow;
    @include break-up(desktop){
        width: auto;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 2rem $layout-padding;
    }
    >div{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 1rem;
        @include break-up(desktop){
            width: 45%;
            gap: 1.5rem;
        }
    }
    .input-block{
        width: 100%;
        &.street{
            width: 68%;
            @include break-up(desktop){
                width: 75%;
            }
        }
        &.streetNbr{
            width: 25%;
            @include break-up(desktop){
                width: 19%;
            }
        }
        &.npa{
            width: 23%;
        }
        &.city{
            width: 70%;
        }
    }
    .connexion-form-buttons{
       justify-content: flex-end;
       width: 100%;
       margin-top: 2rem;
        @include break-down(desktop){
            button, a{
                width: 100%;
            }
        }
    }
}
