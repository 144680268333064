@import "/src/app/styles/for-components";


.cart{
    position: relative;
    max-width: 1280px;
    margin: auto;
    padding: 0;
    @include break-up(desktop){
        padding: 0 $layout-padding;
    }
}
