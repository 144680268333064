@import "/src/app/styles/for-components";

.empty-cart{
    display:flex;
    flex-flow: column wrap;
    justify-content:center;
    align-items:center;
    gap: 1rem;
    width: 95%;
    margin: auto;
    padding: 2rem;
    background-color: $white;
    border-radius: $border-big;
    box-shadow: $shadow;
    text-align: center;
    @include break-up(desktop){
        width: 100%;
    }
    & button{
        margin: 2rem 0;
    }
}
