@import "/src/app/styles/for-components";

.one-column h2 em,
.column-text h2 em,
.page-footer h2 em {
  font-size: inherit;
  font-family: inherit;
  font-style: italic;
  color: #f4161d;
}

.one-column, .twoColumn {

  p { max-width: none; }
  p:has(~ p) { margin-bottom: 1.5rem; }

  a.has-handdrawn {
    display: flex;
    margin-top: 1.5rem;
  }

  dl {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto;
    dt { margin: 0; }
    dd:not(:last-child) { margin-bottom: 2rem; }

    @include break-up(desktop) {
      grid-template-columns: auto auto;
      grid-template-areas: 
      "titre-1 titre-2"
      "text-1 text-2"
      "titre-3 titre-4"
      "text-3 text-4"
      "titre-5 titre-6"
      "text-5 text-6";
      row-gap: 1rem;
      column-gap: 4rem;
      dt:nth-child(1){grid-area: titre-1;}
      dd:nth-child(1){grid-area: text-1;}
      dt:nth-child(2){grid-area: titre-2;}
      dd:nth-child(2){grid-area: text-2;}
      dt:nth-child(3){grid-area: titre-3;}
      dd:nth-child(3){grid-area: text-3;}
      dt:nth-child(4){grid-area: titre-4;}
      dd:nth-child(4){grid-area: text-4;}
      dt:nth-child(5){grid-area: titre-5;}
      dd:nth-child(5){grid-area: text-5;}
      dt:nth-child(6){grid-area: titre-6;}
      dd:nth-child(6){grid-area: text-6;}
    }
  }

}

.page-footer h2 {
  margin-bottom: .75rem;
  @include break-up(desktop) { padding: 0 15%; }
}

.page-footer p a.has-button {
  display: block;
  margin: 2rem auto;
  &:hover { text-decoration: none; }
}
