@import "/src/app/styles/for-components";

/*BOOKING-BOX*/

.bookingBox{
    border-top: inherit;
    --ctl-calendar-font-shorthand: normal normal 400 1.125rem/1.125rem "Open-sans", sans-serif;
    --ctl-calendar-radius: 2rem;
    --ctl-calendar-bg-color: #ffffff;
    --ctl-calendar-bgh-color: rgba(176, 35, 24, 0.2);
    --ctl-calendar-txt-color: #000000;
    --ctl-calendar-txt-active-color: #ffffff;
    --ctl-calendar-txt-inactive-color: #bfbfbf;
    --ctl-calendar-range-color:$red;
    --ctl-calendar-range-light-color: rgba(176, 35, 24, 0.5);
    --ctl-calendar-resticted-bg-color: #ffe680;
    --ctl-calendar-booked-bg-color: #f8c7bc;
    .ct-calendar__controllers__calendarWrapper { margin: 1.5rem auto; }
    .ct-calendar__controllers__calendarDayTooltip { color: #000000 !important; }
    &.smartphone{
        position: fixed;
        top: 0;
        left: 0;
        display: grid;
        grid-template-rows: 60px auto auto;
        width: 100vw;
        height: 100dvh;
        min-height: 100svh;
        background-color: $white;
        z-index: 12;
        .bookingBox-header{
            position: sticky;
            top: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 1rem;
            background-color: $white;
            box-shadow: $shadow;
            z-index: 12;
            >*{
                align-self: center;
            }
            >h3{
                grid-column: 2 / 3;
                justify-self: center;
                margin-bottom: 0;
                white-space: nowrap;
            }
            >svg{
                grid-column: 3 / 4;
                justify-self: flex-end;
                height: 100%;
            }
        }
        .bookingBox-inputs{
            overflow-x: scroll;
            &::-webkit-scrollbar {
                width: 3px;
            }
            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-clip: padding-box;
                background-color: $red;
            }
        }
        .bookingBox-buttons{
            display: grid;
            grid-template-columns: 50% 50%;
            width: 100%;
            padding: 1rem $layout-padding;
            box-shadow: $shadow;
            p{
                grid-column: 1 / 2;
            }
            button{
                grid-column: 2 / 3;
            }
        }
    }
    .buttons{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        gap: 1rem;
        button:nth-child(1){
            white-space: nowrap;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
            &:active{
                color: $red;
                border-color: $red;
            }
        }
    }
    form>.btn-red{
        margin-top: 2rem;
    }
    .loader{
        border-radius: $border-big;
    }
    .info-circle{
        color: $red;
        margin-right: .5rem;
    }
}

.bookingBox-inputDate{
    display: none;
    position: relative;
    justify-content: space-between;
    z-index: 2;
    @include break-up(desktop){
        display: flex;
    }
    .input-block{
        width: 48%;
        input{
            padding: 1rem;
            padding-left: 2rem;
        }
        svg{
            position:absolute;
            top: 55%;
            left: 5%;
            color: $red;
        }
    }
}

.bookingBox-skeleton{
    width: 100%;
    height: 175px;
}

.warning{
    width: 100%;
    padding: 0.5rem;
    font-weight: 600;
    background-color: $yellow;
    border-radius: $border-small;
    span{
        margin-right: 0.5rem
    }
}

/*CALENDAR*/

.bookingBox-calendar{
    padding: 2rem 2.5%;
    @include break-up(desktop){
        position: absolute;
        top: 0%;
        right: 0;
        justify-content: flex-start;
        width: 45vw;
        min-width: 750px;
        padding: 1rem;
        background-color: $white;
        box-shadow: $shadow;
        border-radius: $border-big;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
    }
    .bookingBox-title{
        width: 100%;
        background: $white;
        span{
            display: block;
        }
        >svg.svg-inline--fa{
            position: absolute;
            top: 1.3rem;
            right: 1rem;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
}

/*GUESTS*/

.bookingBox-guests{
    position: relative;
    padding: 0 2.5%;
    border: none;
    @include break-up(desktop){
        margin-top: 1rem;
        padding: 0;
    }
    .input-block{
        display:flex ;
        width: 100%;
        >input,>div{
            padding: 1rem 2.5%;
            cursor: pointer;
            @include break-up(desktop){
                border: $border;
                padding-left: 2rem;
            }
        }
        .users{
            position: absolute;
            left: 2%;
            top: 44px;
            color: $red;
        }
        .chevron{
            position: absolute;
            top: 44px;
            right: 2%;
            color: $red;
            transition: transform 0.5s ease-in-out;
            pointer-events: none;
        }
    }
    .nbrGuests-container{
        position: static;
        @include break-up(desktop){
            position: absolute;
            transform: translateY(-100%);
            opacity: 0;
            visibility: hidden;
            .warning{
                margin-top: 1rem
            }
        }
    }
    .buttonBox.btnEnd{
        display: none;
        @include break-up(desktop){
            display: flex;
        }
    }

}

/*TOTAL*/

.bookingBox-total{
    >p,>div{
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        span{
            display: block;
            font-weight: initial;
            &.discount{
                color: $green;
            }
        }
        p.info{
            cursor: pointer;
            >svg{
                margin-left: 0.5rem;
                path{
                    fill: $red;
                }
            }
        }
    }
    @include break-up(desktop){
        >p:nth-child(1){
            margin-top: 1rem;
        }
    }
    .total{
        border-top: $border;
        font-size: 20px;
        margin-top: .5rem;
        margin-bottom: 1rem;
        padding-top: .5rem;
        @include break-up(desktop){
            margin-bottom: 0;
        }
    }
    .warning{
        display: flex;
        margin: 0.2rem 0;
        p{
            margin-top: 0.6rem;
        }
        svg{
            cursor: pointer;
        }
    }
}

/*SMARTPHONE*/

.bottom-fixed-module{
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100vw;
    padding: 1rem 2.5%;
    background-color: $white;
    z-index: 10;
    box-shadow: $shadow;
    font-size: 16px;
    font-weight: 500;
    >p,>button{
        width: 48%;
    }
    p.warning{
        width: 100%;
        margin-bottom: 1rem;
    }
    .bookingBox-total{
        width: 100%;
    }
}

