
@import "./breakpoints";

/*---COLOR---*/

$black:#353F47;
$white:#FDFCFB;
$white-transparent: rgba(255, 255,255, 0.8);
$background: #EFEBE3;
$light-grey:#DDDDDD;
$dark-grey:#707070;
$red:#F4161D;
$green: #0DBC72;
$blue: #3A68F6;
$yellow: #f9f9c2;

/*---UI---*/

$shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px;
$border: 1px solid $light-grey;
$border-round: 100vw;
$border-big: 20px;
$border-medium: 15px;
$border-small: 8px;

/*---LAYOUT---*/

$header-height: var(--header-height);
$layout-padding: var(--layout-padding);

:root{
    --header-height: 60px;
    --layout-padding: 2.5%;
    @include break-up(big-desktop){
        --header-height: 100px;
        --layout-padding: 5%;
    }
}
