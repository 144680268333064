@import "/src/app/styles/for-components";

.connexion-choice{
    position: relative;
    padding: 0 1rem;
    display: flex;
    flex-flow: column wrap;
    max-width: 1280px;
    width: 95%;
    margin: auto;
    margin-bottom: 4rem;
    background-color: $white;
    border-radius: $border-big;
    @include break-up(desktop){
        width: auto;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 2rem $layout-padding;
        box-shadow: $shadow;
    }
    >div{
        width: 100%;
        padding: 2rem 0;
        border-bottom: $border;
        @include break-up(desktop){
            width: 45%;
            padding: 0;
            border-bottom: none;
        }
    }
    dl{
        display: grid;
        grid-template-rows: repeat(4, auto);
        @include break-up(desktop){
            grid-template-rows: repeat(4, 1fr);
        }
    }
    dd{
        margin: 1rem 0;
        @include break-down(desktop){
            button, a{
                width: 100%;
            }
        }
    }
    dt{
        align-self: flex-end;
        margin-bottom: .5rem
    }
    button{
        margin: 0;
    }
}
