
@mixin font-heading {
    font-family: "Noto Serif Display", system-ui , sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
    "wdth" 100;
    font-weight: 400;
    color: #161615;
}

@mixin font-basic {
    font-family: "DM Sans", system-ui , sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

@mixin font-floating {
    font-size: clamp(18px, 1.2vw, 25px);
    font-family: "Handmade", Arial, Helvetica, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}