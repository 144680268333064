@import "react-loading-skeleton/dist/skeleton.css";
@import "react-day-picker/dist/style.css";
@import "@splidejs/splide/dist/css/splide.min.css";
@import "@ct-react/calendar/dist/ct-react.calendar.css";
@import "for-components";
@import "fonts";


*{
    outline: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
    @include font-basic;
    @include break-down(small-mobile){
        font-size: 14px;
    }
}

html{
    scroll-behavior: smooth;
}

body{
    max-width: 1920px;
    margin: auto;
    background-color: $background;
    &.no-scroll {
        overflow: hidden;
        height: 100%;
    }
}

ul,li{
    list-style: none;
    font-size: 1em;
    color: currentColor;
}

a{
    font-size: 1rem;
    color: currentColor;
    text-decoration: inherit;
    cursor: pointer;
}

*.disabled-link, *:disabled{
    pointer-events: none;
    cursor: not-allowed;
}

button{
    border: inherit;
    color: $black;
    background-color: transparent;
    -webkit-appearance: none;
}

label{
    position: relative;
    font-size: 1rem;
    @include break-up(desktop){
        font-size:0.729em;
    }
}

::placeholder{
    font-size: 0.888rem;
}

/*---SCROLL BAR---*/

::-webkit-scrollbar {
width: 10px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
background-clip: padding-box;
background-color: $black;
}

/*UTILS*/

.hide{
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
}

.show{
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-in-out;
}

/*SKELETON*/

.skeleton-card-btn{
    min-height: 48px;
    width: 120px;
    margin-top: 2rem;
}

/*___TEXT STYLING___*/

h1:not(.home-page-h1), h2, h3, dl > dt {
    @include font-heading;
    span{
        @include font-heading;
        font-size: 1em;
        color: $red;
        font-style: italic;
    }
}

h4{
    @include font-basic;
    span{
        font-size: 0.8em;
        font-weight: 200;
        font-style: italic;
        color: $red;
    }
}

h1{
    font-size: clamp(30px, 2.604vw ,50px);
    span{
        white-space: nowrap;
    }
}

h2{
    font-size: clamp(28px, 2.083vw ,40px);
    line-height: 1.2em;
    margin-bottom: 1rem;
    @include break-up(desktop){
        margin-bottom: 2rem;
    }
}

h3, dl:not(.connexion-choice *) > dt {
    font-size: clamp(20px, 1.458vw ,24px);
    margin-bottom: 1rem;
}

h4{
    font-size: clamp(16px, 1.2vw ,18px);
    color: currentColor;
    &.marged{
        margin: 1rem 0;
    }
}

h5{
    font-size: clamp(12px, 0.8vw ,14px);
    color: currentColor;
}

p { max-width: 75ch; }

p, dl > dd {
    line-height: 1.7;
    font-size: 14px;
    @include break-up(big-desktop){
        font-size: 1em;
    }
}

/* force format html descriptions */

.r-format-html {

  div, p, span, a, li, dt, dd, strong, em, i, u, b, blockquote, code, pre  {
    background-color: transparent !important;
    color: currentColor !important;
    text-align: left;
    font-family: "DM Sans", system-ui , sans-serif !important;
    font-size: 1rem !important;
    @include break-down(small-mobile) {
      font-size: 14px !important;
    }
  }

  strong, b, strong > *, b > * { font-weight: 600 !important; }

  p ~ br, p > br:first-child, p > br:last-child { display: none; }

  p {
    margin: 1rem 0 1rem 0;
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }

  pre { text-wrap: unset; }
  ul { padding-inline-start: 1rem }
  hr { display: none !important; }
  sup, sub { font-size: .75rem !important; }

}

/*___LAYOUT___*/

section{
    position: relative;
    padding: 3rem $layout-padding;
    @include break-up(desktop){
        padding: 6rem $layout-padding;
    }
    ul, ol, dl { margin: 1rem 0; }
    ul, ol {
        padding-left: 1rem;
        li:not(.splide__slide){
            list-style: url("../../assets/svg/chevron-right-solid.svg");
            line-height: 2;
        }
    }
    .strong{
        font-weight: bold;
    }
    p>a{
        font-weight: bold;
        &:hover{
            color: $red;
            text-decoration: underline
        }
    }
}

hgroup.page-title {

  text-align: center;
  width: fit-content;
  margin: 1rem auto 0 auto;
  padding: 0 $layout-padding;

  h1 em {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: italic;
    color: $red;
  }

  p { margin: 1rem auto 0 auto; }

  @include break-down(desktop) {
    text-align: left;
    h1 {
      font-size: 40px;
      line-height: 1.1;
    }
  }

  @include break-up(desktop) {
    margin: 6rem auto 0 auto;
  }

}

.floating-section{
    width: 100%;
    display: inline-flex;
    flex-flow: column nowrap;
    padding: 0 0 2rem 0;
    @include break-up(desktop){
      width: 50%;
      section { padding: 2rem 0; }
      &:nth-of-type(even) { padding-left: 2rem; }
    }
}

.basic-50-50{
    display: flex;
    flex-flow: column nowrap;
    @include break-up(desktop){
        flex-flow: row wrap;
        justify-content: space-between;
    }
    >div{
        width: 100%;
        @include break-up(desktop){
            width: 45%;
        }
    }
    div:nth-child(2){
        >div{
            margin-top: 6rem;
        }
    }
}

address{
    display: flex;
    flex-direction: column;
    a{
        position: relative;
        margin: 1rem 0;
        padding-left: 2rem;
        color: currentColor;
        svg{
            position: absolute;
            transform: translateX(-2rem);
        }
        &:hover{
            color: $red;
        }
    }
}

.social-network{
    display: flex;
    gap: 1rem;
}

.smartphone{
    display: block;
    @include break-up(desktop){
        display: none;
    }
}

.svg-inline--fa{
    height: 16px;
    width: 16px;
}

*[data-gloss]{
    position: relative;
    &:hover:after{
        background: $black;
        font-size:0.611rem ;
        border-radius: 5px;
        bottom: -34px;
        color: $white;
        content: attr(data-gloss);
        left: 50%;
        transform: translateX(-50%);
        padding: 6px 15px;
        position: absolute;
        z-index: 999;
        width: auto;
        white-space: nowrap;
    }
    &:hover:before {
        border: solid;
        border-color: $black transparent;
        border-width: 0 6px 6px 6px;
        bottom: -9px;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        z-index: 999;
    }
}

/*___CALENDAR___*/

.ct-calendar__controllers__calendarDay.cds-default{
    &.cds-valid{
        &.cds-selected-end{
            background-color: $red;
        }
        &.cds-hovered{
            span{
                border-color: $red;
            }
            &:hover span{
                border-color: $red;
            }
        }
        &.cds-selected-start, &.cds-selected-end{
            background-color: $red;
            border-color: $red;
            span{
                background-color: $red;
                color: $white;
            }
            &.cds-hovered{
                span{
                    border-color: $red;
                }
            }
        }
    }
    &.cds-blocked{
        span{
            color: $light-grey;
        }
    }
    &.cds-selected-into, &.cds-selection-hovered-into, &.cds-selection-hovered-end, &.cds-selection-hovered-next-to-start,&.cds-hover{
        background-color: rgba(244, 22, 29, 0.5);
        &:not(.cds-blocked.cds-selection-hovered-end) span{
            color: $white;
        }
    }
    &.cds-today{
        span{
            border-color: $blue;
        }
    }

}


/*Z-INDEX*/

.searchModule{
    z-index: 9;
}

header{
    z-index: 11;
}

.burger,.subnav>ul{
    z-index: 12;
}

/*___INPUTS___*/

/*toggle button*/

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    & input{
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    &:before{
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
}

.toggle-btn{
    &:checked + .slider{
        background-color: $black;
    }
    &:focus + .slider{
        box-shadow: 0 0 1px $black;
    }
    &:checked + .slider:before{
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
}

.slider.round {
    border-radius: 34px;
    border: 1px solid $black;
    &:before{
        border-radius: 50%;
    }
}

input[type="text"],input[type="tel"],input[type="email"],select,textarea{
    border: 1px solid $light-grey;
    padding: 1rem 1rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:focus{
        border-color: #4671ff;
    }
}

select {
    cursor: pointer;
    position: relative;
    color: $black;
    &:before{
        position: absolute;
        right: 0;
        top: 0;
        content: url("../../assets/svg/chevron-down-solid.svg");
    }
}

textarea{
    min-height: 30vh;
    max-width: 100%;
}

/*Number*/
input[type="number"]{
    -webkit-appearance: none;
    appearance: none;
    min-width: 7.810rem;
    padding: 0.625rem;
    padding-right:2.8rem ;
    &:focus{
        border-color: $black;
    }
    &::-webkit-inner-spin-button,::-webkit-outer-spin-button{
        -webkit-appearance: none;
    }
}

/*Checkbox*/
input[type="checkbox"]:not(.toggle-btn) {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: $black;
    width: 18px;
    height: 18px;
    border: 1px solid $black;
    border-radius: 0.208rem;
    display: grid;
    place-content: center;
    cursor: pointer;
    &:before{
        content: "";
        width: 12px;
        height: 12px;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        /* Windows High Contrast Mode */
        background-color: $black;
        @include break-up(desktop){
            width: 0.521rem;
            height: 0.521rem;
        }
    }
    &:checked::before{
        transform: scale(0.9);
    }
    &.read-only{
        border-color: $dark-grey;
        &::before{
            background-color: $dark-grey;
        }
    }
    @include break-up(desktop){
        width: 0.885rem;
        height: 0.885rem;
    }
}

/*Radio*/
input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: $black;
    width: 16px;
    height: 16px;
    border: 1px solid $red;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
    &:before{
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: $shadow;
        background-color: $red;
    }
    &:checked::before{
        transform: scale(0.9);
    }
}

/*Range*/
input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
    &::-webkit-slider-runnable-track{
        -webkit-appearance: none;
        height: 0.521em;
    }
    &::-moz-range-track{
        -moz-appearance: none;
        height: 0.521em;
    }
    &::-ms-track{
        appearance: none;
        height: 0.521em;
    }
    &::-webkit-slider-thumb{
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        background-color: #ffffff;
        border: 3px solid $black;
        cursor: pointer;
        margin-top: -0.25em;
        pointer-events: auto;
        border-radius: 50%;
    }
    &::-moz-range-thumb{
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        cursor: pointer;
        border-radius: 50%;
        background-color: #ffffff;
        border: 3px solid $black;
        pointer-events: auto;
        border: none;
    }
    &::-ms-thumb{
        appearance: none;
        height: 1em;
        width: 1em;
        cursor: pointer;
        border-radius: 50%;
        background-color: #ffffff;
        border: 3px solid $black;
        pointer-events: auto;
    }
    &:active::-webkit-slider-thumb{
        transform: scale(1.5);
    }
}

.rangeLabel{
    min-width: 95%;
    margin: 2rem auto 1rem auto;
    @include break-up(desktop){
        min-width: 300px;
        margin: 1rem auto 2rem auto;
    }
    span{
        position: absolute;
        right: 5%;
    }
    span:nth-of-type(1){
        bottom: -2rem;
    }
    span:nth-of-type(2){
        top: -2rem;
    }
}

.inputRangeColor{
    position: absolute;
    left: 0;
    width: 100%;
    min-width: 15.625rem;
    height: 0.521rem;
    border-radius: 50px;
}

