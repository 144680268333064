@import "/src/app/styles/for-components";

.cart-content{
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    @include break-up(desktop){
        flex-direction: row;
    }
    .cart-price{
        position: sticky;
        top: 0;
        width: 95%;
        height: fit-content;
        margin: auto;
        padding: 2rem 1rem 1rem 1rem;
        box-shadow: $shadow;
        background-color: $white;
        border-radius: $border-big;
        @include break-up(desktop){
            width: 30%;
            margin: 0;
        }
        h3{
            margin-bottom: 1rem;
            border-bottom: $border;
            padding-bottom: 0.5rem;
            display: block;
        }
        >div{
            display:flex;
            flex-flow: row wrap;
            justify-content:space-between;
            align-items:center;
            margin-bottom: 0.5rem;
            span{
                font-weight: 500;
            }
        }
        div:has(.price){
            padding: 0.5rem 0;
            border-bottom: 1px dashed $light-grey;
            p:nth-child(1){
                font-weight: bold;
            }
        }
        .price{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .cart-price-total{
            p, span{
                font-weight: bold;
                font-size: 18px;
            }
        }
        .cart-price-account{
            color: $green;
            p, span{
                font-weight: bold;
                font-size: 18px;
            }
        }
        .cart-price-button{
            justify-content: flex-end;
            margin-top: 2rem;
        }
    }
}

