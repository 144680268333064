@import "/src/app/styles/for-components";


.articles-header{
    display: none;
    flex-flow: row wrap;
    margin: 0;
    width: 100%;
    align-items: center;
    padding: 2rem $layout-padding;
    pointer-events: none;
    @include break-up(desktop){
        position: absolute;
        top: 10rem;
        left: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        z-index: 3;
    }
}

.articles-settings{
    display: none;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: $background;
    justify-content: space-between;
    padding: 1rem $layout-padding;
    padding-top: 5rem;
    z-index: 2;
    @include break-up(desktop){
        display: flex;
    }
    .filters{
        display: none;
        gap: 1rem;
        padding: 1rem 0;
        @include break-up(desktop){
            display: flex;
        }
        label{
            display: flex;
            gap: 0.5rem;
        }
        input[type=checkbox]{
            border-color: $black;
            &:checked{
                border-color: $red;
                &::before{
                    background-color: $red;
                }
            }
        }
    }
}

.articles-container{
    min-height: 50vh;
    padding: 1rem $layout-padding;
    @include break-up(desktop){
        min-height: calc(100% - 2 * 5.208rem - 1rem);
        height: auto;
        margin: auto;
        display: grid;
        grid-template-columns: 100% 50%;
        align-items: baseline;
        overflow-x: clip;
    }
    .articles{
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(90%, auto));
        gap: 1rem;
        margin-bottom: 6.25rem;
        @include break-up(desktop){
            grid-template-columns: repeat(auto-fit, minmax(400px, auto));
        }
        @include break-up(big-screen){
            gap: 2rem;
        }
        &.autoGrid{
            @include break-up(desktop){
                grid-template-columns: repeat(auto-fit, minmax(300px, auto));
            }
            @include break-up(big-screen){
                grid-template-columns: repeat(auto-fit, minmax(335px, auto));
            }
        }
        &.thirdGrid{
            height: 100%;
            @include break-up(desktop){
                grid-template-columns: repeat(auto-fit, minmax(300px, 23.6%));
            }
            @include break-up(big-screen){
                grid-template-columns: repeat(auto-fit, minmax(335px, 23.6%));
            }        
        }
    }
    &.empty{
        margin-bottom: 4rem;
    }
    .articles-btnMap{
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        z-index: 1;
        @include break-up(desktop){
            display: none;
        }
    }
    
}

.empty-articles{
    @include break-down(desktop){
        margin-top: 1rem;
    }
}

/*MAP*/

.articles-map{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    z-index: 10;
    padding-bottom: 5.5rem;
    background-color: $white;
    @include break-up(desktop){
        position: initial;
        height: calc(100svh - 9rem - 1rem);
        width: 100%;
        position: sticky;
        top: 9rem;
        align-self: start;
        padding-left: 2rem;
        padding-bottom: inherit;
        z-index: 1;
        background-color: $background;
    }
    &.showMap{
        display: block;
    }
    .btn-red{
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        z-index: 11;
    @include break-up(desktop){
        display: none;
    }
    }
}


.ct-map__container.overloaded-map{
    height: 100%!important;
    @include break-up(desktop){
        overflow: hidden;
    }
}

.article-popUp-title{
    padding: 0.5rem;
    font-weight: bold;
}

.overloaded-map{
    & .leaflet-control-container{
        position: relative;
        top: 82%;
    }
    & .leaflet-right{
        position: relative;
        top: 82%;
    }
    & .leaflet-popup-content-wrapper{
        padding: 0;
        overflow: hidden;
    }
    & .leaflet-popup-content{
        margin: 0;
    }
    & .leaflet-container{
        & a{
            color: $black;
            &.leaflet-popup-close-button{
                background-color: rgba(176, 35, 24, 0.8);
                color: $white;
                border-radius: 50px;
                top: 10px;
                right: 10px;
                &:hover{
                    color: $red;
                    background-color: $white;
                }
            }
        }
    }
    & div.ct-map__defaultClusterInnerMarker{
        width: 55px;
        height: 55px;
        border-color: $red;
        background: var(--ctl-calendar-range-light-color);
        color: $white;
        transition: transform 0.25s ease-in-out;
        &:hover{
            transform: scale(1.2);
            transition: transform 0.25s ease-in-out;
        }
        &:before{
            height: calc(100% - 15px);
            width: calc(100% - 15px);
            border-color: $red;
        }
        &:after{
            height: calc(2 * var(--ctl-map-marker-size));
            width: calc(2 * var(--ctl-map-marker-size));
            background-color: $red;
        }
    }
}

/*POP UP*/

.article-popUp{
    width: 100%;
    height: auto;
    & .article-popUp-img{
        height: 13.25rem;
        width: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
    }
}

.toggle-map{
    display: none;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    @include break-up(desktop){
        display: flex;
    }
}

/*PAGINATION*/

.articles-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6.25rem auto;
    @include break-up(desktop){
        margin-top: 1.25rem;
        margin-bottom: 12.5rem;
        gap: 1rem;
    }
}

.pagination-container{
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 2vw;
    @include break-up(desktop){
        gap: 1rem;
    }
}

.pagination{
    width: 10vw;
    height: 10vw;
    max-width: 2.666rem;
    max-height: 2.666rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
        color: $red;
        box-shadow: $shadow;
        border-color: $red;
    }
    &:active{
        color: $white;
        background-color: $red;
        border-color: $red;
    }
    &.currentPage{
        color: $white;
        background-color: $red;
        border-color: $red;
    }
}

/*CTA*/

.cta{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    text-align: center;
    a{
        margin-top: 2rem;
    }
}