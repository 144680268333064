@import "/src/app/styles/for-components";

.notFound{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    min-height: 70vh;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: grayscale(30%);
    & *:not(.blackVeil){
        z-index: 1;
    }
}

.blackVeil{
    position: absolute;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
}

.error{
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    text-align: center;
    font-size: 7rem;
    color: $white;
    @include break-up(desktop){
        font-size: 12rem;
    }
}

.errorText{
    width: 100%;
    text-align: center;
    color: $white;
    font-size: 2rem;
}

.errorText2{
    width: 100%;
    text-align: center;
    color: $white;
    font-size: 1rem;
    @include break-up(desktop){
        font-size: 1.5rem;
    }
}
