@import "/src/app/styles/for-components";


.article{
    max-width: 1280px;
    margin: auto;
    padding: 0;
    @include break-up(desktop){
        padding: 0 $layout-padding;
    }
}


/*HEADER*/

.article-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    @include break-up(desktop){
        >div:nth-child(1){
            margin: 2rem 0 1.5rem 0;
        }
    }
    &.desktop{
        display: none;
        @include break-up(desktop){
            display: flex;
        }
    }
    &.smartphone{
        margin-bottom: 2rem;
        @include break-up(desktop){
            display: none;
        }
        .goBack{
            margin-bottom: 0.8rem;
        }
    }
    .goBack{
        width: fit-content;
        cursor: pointer;
        .article-header-chevronLeft{
            @include break-down(mobile){
                height: 13px;
            }
            height: 16px;
            margin-right: 0.25rem;
        }
    }
    .title{
        h1{
            font-size: clamp(32px, 36px ,40px);
            margin: 0.5rem 0 ;
            color: $red;
            text-align: initial;
            line-height: 1.1;
            @include break-up(desktop){
                display: block;
            }
        }
        .price-span{
            font-size: 1.5em;
            display: block;
            margin-top: 1rem;
        }
    }
    .article-header-info{
        display: flex;
        @include break-up(desktop){
            display: grid;
            grid: 100%/50% auto;
        }
        .price-span{
            display: none;
            @include break-up(desktop){
                display: flex;
                align-items: baseline;
                gap: 1rem;
                flex-direction: row;
                font-size: 1.5rem;
            }
        }
    }
    .title-skeleton{
        height: 40px;
        width: 50%;
        margin-bottom: 0.5rem;
    }
}

/*INFO*/

.article-info{
    position: relative;
    margin: auto;
    margin-top: 1rem;
    padding: 0 $layout-padding;
    @include break-up(desktop){
        padding: 0;
        display: flex;
        justify-content: space-between;
        gap: 3rem;
        margin-top: 3rem;
    }
    .article-description{
        height: fit-content;
        padding-top: 2rem;
        @include break-up(desktop){
            width: 60%;
            padding-top: 0;
        }
        h3{
            margin: 2rem 0 1rem 0;
        }
        .article-header-icones{
            @include break-up(desktop){
                display: none;
            }
        }
    }
}

.article-header-icones{
    display: flex;
    gap: 0.5rem;
    @include break-up(desktop){
        justify-self: flex-end;
        margin-right: 1rem;
    }
    >a,button,div{
        height: 2.5rem;
        width: 2.5rem;
        display:flex;
        justify-content:center;
        align-items:center;
        color: $red;
        border-radius: $border-small;
        cursor: pointer;
        @include break-up(desktop){
            &:hover{
                background-color: $red;
                color: $white;
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            }
        }
        &:active{
            background-color: $red;
            color: $white;
            box-shadow: none;
        }
        &.facebook{
            background-color: #4968AD;
            color: $white;
        }
        &.whatsapp{
            background-color: #65CF72;
            color: $white;
        }
        &.email{
            background-color: $red;
            color: $white;
        }
        &.copy{
            background-color: $black;
            color: $white;
        }
        &.print{
            background-color: $white;
            color: $black;
        }
    }
    .copy {
        z-index: 1;
        animation: copy 1s ease normal paused;
        svg{
            pointer-events: none
        }
    }
      @keyframes copy {
        30% { transform: scale(1.2); }
        40%, 60% { transform: rotate(-20deg) scale(1.2); }
        50% { transform: rotate(20deg) scale(1.2); }
        70% { transform: rotate(0deg) scale(1.2); }
        100% { transform: scale(1); }
      }
}

/*ARTICLE IMAGE GRID*/

.article-image{
    position: relative;
    .article-image-grid{
        position: relative;
        display: none;
        margin: auto;
        overflow: hidden;
        margin-top: 1rem;
        gap: 0.8em;
        @include break-up(desktop){
            width: 100%;
            min-height: 300px;
            display: grid;
            grid: repeat(2, minmax(150px, 250px)) / 50% 25% 25%;
        }
        >div,>span{
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            span{
                width: 100%;
                height: 100%;
            }
        }
        >div:nth-child(1), >span:nth-child(1){
            grid-row: 1/3;
            grid-column:1/2 ;
        }
        >div:nth-child(2), >span:nth-child(2){
            grid-row: 1/2;
            grid-column: 2/3;
        }
        >div:nth-child(3), >span:nth-child(3){
            grid-row: 1/2;
            grid-column: 3/4;
        }
        >div:nth-child(4), >span:nth-child(4){
            grid-row:2/3 ;
            grid-column:2/3 ;
        }
        >div:nth-child(5), >span:nth-child(5){
            grid-row:2/3 ;
            grid-column:3/4 ;
        }
        .grid-btn{
            @include break-up(desktop){
                position: absolute;
                display:flex;
                justify-content:center;
                align-items:center;
                gap:0.5rem;
                bottom: 1rem;
                right: 1rem;
                padding: 0.5rem 1rem;
                background-color: $light-grey;
                border-radius: 100px;
                font-size: 0.777em;
                cursor: pointer;
                svg{
                    height: 16px;
                }
                &:hover{
                    background-color: $blue;
                    color: $white;
                    svg path{
                        fill: $white;
                    }
                }
            }
        }
    }
    .article-image-empty{
        position: relative;
        width: 100%;
        height: 250px;
        margin: auto;
        background-color: $light-grey;
        margin-top: 1rem;
        display:flex;
        flex-flow: column wrap ;
        justify-content:center;
        align-items:center;
        gap: 3rem;
        color: $white;
        @include break-up(desktop){
            height: 25vw;
        }
        svg{
            height: 20%;
        }
    }
    .article-image-carousel{
        width: 100%;
        @include break-up(desktop){
            & .splide__slide{
                display: flex;
                justify-content: center;
            }
        }
    }
    .article-image-carousel-block{
        position: relative;
        @include break-up(desktop){
            display: none;
        }
        .article-image-carousel-close{
            display: none;
        }
        .article-image-carousel-current{
            position: absolute;
            bottom: 1em;
            right: 1em;
            padding: 5px 8px;
            border-radius: 5px;
            background: $white;
        }
        &.show{
            position: fixed;
            display: flex;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            display:flex;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 999;
            padding-top: calc((100vh - 250px) / 2);
            @include break-up(desktop){
                padding-top: calc((100vh - 40vw) / 2);
            }
            .splide { position: absolute; }
            .article-image-carousel-close{
                display: block;
                position: absolute;
                top: 1.5rem;
                right: 1.5rem;
                color: $white;
                cursor: pointer;
                @include break-up(desktop){
                    top: 2.5rem;
                    right: 2.5rem;
                }
            }
        }
        .splide__arrow svg{
            pointer-events: none;
        }
    }
    .article-image-carousel-close{
        width: 30px;
        height: 30px;
    }
}

/*ARTICLE-FORM*/

.article-form{
    margin-top: 2.5rem;
    @include break-down(desktop){
        h3{
            font-size: clamp(28px, 2.083vw, 40px);
        }
    }
    @include break-up(desktop){
        position: sticky;
        top: 6rem;
        width: 40%;
        height: fit-content;
        margin-top: 0;
        padding: 1rem;
        border-radius: $border-big;
        background-color: $white;
        box-shadow: $shadow;
        opacity: 1;
        visibility: visible;
    }
    &:has(.contact-form){
        width: 100%;
        @include break-up(desktop){
            width: calc(55% - 3rem);
        }
    }
}

/*ARTICLE-MAP*/

.article-map{
    max-width: 1280px;
    padding: 2rem $layout-padding;
    margin: auto;
    @include break-up(desktop){
        padding: 0 $layout-padding;
    }
}

/*ARTICLE-ANNONCES*/

.article-annonces{
    max-width: 1280px;
    margin: auto;
    padding: 2rem $layout-padding;
    @include break-up(desktop){
        padding: 3rem $layout-padding;
    }
}

/*ARTICLE SALE EQUIPMENT*/

.article-sale-equipment{
    display: flex;
    flex-flow: row wrap;
    background-color: $white;
    border-radius: $border-big;
    margin: 2rem 0;
    padding: 0.5rem 1rem;
    @include break-up(desktop){
        padding: 1rem;
    }
    ul{
        list-style: none;
        margin: 0;
        width: 100%;
        padding: 0;
        @include break-up(desktop){
            width: 100%;
        }
        @include break-up(big-screen){
            width: 50%;
        }
    }
    li, >div{
        p, b{
            font-size: 1rem;
            @include break-up(desktop){
                font-size: 0.888rem;
            }
        }
    }
    li {
        display: flex;
        padding: 0.5rem 0px;
        line-height: 1.5;
        @include break-up(desktop){
            grid: 100%/36% 10% 54%;
        }

    }
    >div{
        width: 100%;
        padding: 0.5rem 0;
    }
}

/*ARTICLE RENTAL EQUIPMENT*/

.article-rent-equipment{
    margin-top: 3rem;
    @include break-up(desktop){
        margin: 3rem 0 2rem 0;
    }
    & ul{
        display: flex;
        flex-flow: row wrap;
    }
    & li{
        width: 50%;
        display: flex;
        align-items: baseline;
        gap: 1rem;
        padding: 1rem 0px;
    }
    .lineThrough{
        text-decoration: line-through;
    }
}

.line-through{
    text-decoration: line-through;
}


@media only print {
    .searchModule,form,.section:has(.splide),footer,*[data-gloss]:before,*[data-gloss]:after,.splide__arrow{
        display: none;
    }
    .splide__track{
        aspect-ratio: 1.6 / 1;
        height: auto;
    }
}
