@import "/src/app/styles/for-components";

.stepper{
    display: flex;
    justify-content: center;
    padding: 1rem 0 3rem 0;
    @include break-up(desktop){
        padding: 3rem 0;
    }
    .step{
        width: 15%;
        white-space: nowrap;
        cursor: pointer;
        @include break-up(desktop){
            width: auto;
            a, div{
                display: flex;
                flex-flow: row nowrap;
            }
        }
    }
    .stepper-icone{
        width: 100%;
        display:flex;
        justify-content:center;
        align-items:center;
        align-self: center;
        border-radius: 50px;
        aspect-ratio: 1/1;
        height: fit-content;
        color: $light-grey;
        background: currentColor;
        @include break-up(desktop){
            width: 70px;
        }
        &.isActive{
            color: $red;
        }
        & svg{
            width: auto;
            height: 50%;
            color: $white;
        }
    }
    & .stepper-info{
        position: relative;
        display:flex;
        flex-flow: row wrap;
        justify-content:center;
        align-items:center;
        margin-top: 0.5rem;
        @include break-up(desktop){
            margin: 0;
        }
        & h5{
            position:absolute;
            top: 100%;
            font-size: 0.85rem;
            width:max-content;
            @include break-up(desktop){
                font-size: inherit;
                width: 100%;
                margin: 0 2rem;
                position: static;
                top: inherit;
                left: inherit;
                transform: inherit;
                font-size: initial;
            }
        }
        & span{
            font-size: 0.75rem;
        }
    }
    & .connector{
        position: relative;
        flex: 0 0 13%;
        color: $light-grey;
        &.isActive{
            color: $red;
        }
        &:before{
            content: "";
            width: 100%;
            height: 3px;
            background-color: currentColor;
            position:absolute;
            top: 44%;
            left: 0;
            transform: translateY(-50%);
            @include break-up(desktop){
                width: 90%;
                top: 50%;
            }
        }
    }
}
