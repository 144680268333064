@import "/src/app/styles/for-components";

.veil{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    background-color: rgba(255,255,255,0.7);
    display:flex;
    justify-content:center;
    align-items:center;
    z-index: 12;
    .pop-up{
        position: relative;
        width: 95%;
        padding: 1rem;
        padding-top: 4rem;
        background-color: $white;
        border-radius: $border-big;
        box-shadow: $shadow;
        @include break-up(desktop){
            width: auto;
        }
        h3{
            text-align: center;
            max-width: 40ch;
            margin: 1rem auto;
        }
        p{
            text-align: center;
            max-width: 35ch;
            margin: auto;
        }
        >svg{
            position: absolute;
            top: 1rem;
            right: 1rem;
            height: 28px;
            width: 28px;
            cursor: pointer;
        }
        .result-icone{
            margin: 0 auto 2rem auto;
            svg{
                display: block;
                width: 60px;
                height: 60px;
                margin: auto;
                color: $red;
                &.succeeded{
                    color: $green;
                }
            }
        }
        .form-result-buttons{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.9rem;
            margin: 3rem auto 0 auto;
            @include break-up(desktop){
                gap: 4rem;
            }
        }
    }
}