@import "/src/app/styles/for-components";

.twoColumn{
    display: flex;
    flex-flow: column-reverse wrap;
    justify-content: space-between;
    gap: 4rem;
    @include break-up(desktop){
        flex-flow: row nowrap;
        gap: 0;
    }
    &.revertColumn{
        flex-direction: column-reverse;
        @include break-up(desktop){
            flex-direction: row-reverse;
        }
    }
    .column{
        @include break-up(desktop){
            display: flex;
            justify-content: flex-start;
            flex-flow: column;
            margin: 0;
        }
    }
    .column-text{
        width: 100%;
        @include break-up(desktop){
            width: 48%;
        }
        h1,h2{
            margin-bottom: 2rem
        }
    }
    .column-image{
        width: 100%;
        min-height: 300px;
        @include break-up(desktop){
            width: 48%;
        }
        & img{
            position: sticky;
            top: 5.5rem;
            min-width: 95vw;
            aspect-ratio: 1.3 / 1;
            width: 100%;
            object-fit: cover;
            border: 8px solid white;
            background: $white;
            box-shadow: $shadow;
            @include break-up(desktop){
                min-height: inherit;
                min-width: inherit;
                aspect-ratio: 1.333 / 1;
            }
        }
    }
    .btn{
        margin-top: 2rem;
    }
}

