@import "/src/app/styles/for-components";

.hero{
    height: calc(100svh - $header-height);
    hgroup{
        h1{
            span{
                position: relative;
                padding: 0.5rem;
                @include font-heading;
                font-size: 1em;
                background-color: $background;
            }
            span:nth-child(1){
                font-weight: 400;
                z-index: 1;
            }
            .crans{
                font-weight: 300;
                font-style: italic;
                z-index: 0;
            }
        }
        p{
            position: relative;
            width: fit-content;
            margin-top: 0.45rem;
            padding: 0 1rem;
            font-size: clamp(20px, 1.458vw ,28px);
            background-color: $black;
            letter-spacing: 1.3px;
            text-transform: uppercase;
            color: $white;
            z-index: 1;
        }
    }
    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.8);
        z-index: -1;
    }
}

.section-annonces{
    @include break-up(desktop){
        text-align: center;
    }
    >p{
        margin: auto;
    }
    .switchs{
        display: flex;
        gap: 2rem;
        width: fit-content;
        margin: auto;
        padding: 2rem 0;
        h4{
            cursor: pointer;
            &.activ{
                color: red;
                text-decoration: underline;
            }
            &:hover{
                color: $red;
            }
        }
    }
}

.section-vendre{
    display: flex;
    flex-flow: row wrap;
    background-color: $white;
    h2{
        width: 100%;
    }
    .text{
        width: 100%;
        @include break-up(desktop){
            width: 60%;
        }
    }
    .links{
        display:flex;
        flex-direction: row;
        justify-content:space-between;
        align-items:center;
        gap: 2rem;
        width: 100%;
        margin-top: 3rem;
        @include break-up(desktop){
            width: 40%;
            margin-top: 0;
            flex-direction: column;
            justify-content:center;
            a{
                min-width: 180px;
            }
        }
    }
    
}

.section-services{
    padding-bottom: 15rem;
    @include break-up(desktop){
        text-align: center;
        padding-bottom: initial;
    }
    >p{
        margin: auto;
    }
    .services{
        display: flex;
        flex-flow: column wrap;
        text-align: left;
        margin-top: 4rem;
        @include break-up(desktop){
            flex-flow: row wrap;
            gap: 15%;
        }
        >div{
            display: flex;
            width: 100%;
            height: fit-content;
            flex-flow: row wrap;
            justify-content: space-between;
            margin-bottom: 4rem;
            @include break-up(desktop){
                width: 40%;
            }
            .service-icone{
                fill: $red;
                width: 60px;
                @include break-up(desktop){
                    margin-bottom: 1rem
                }
                svg{
                    width: 60px;
                    height: 60px;
                }
            }
            h3{
                width: calc(100% - 60px - 1rem);
                @include break-up(desktop){
                    width: 100%;
                }
            }
            .btn{
                margin-top: 2rem;
            }
        }
    }
    .floating-img{
        position: absolute;
        bottom: -5px;
        right: 0;
        height: 60vw;
        max-height: 400px;
        @include break-up(desktop){
            right: 250px;
        }
    }
    .floating-text{
        position: absolute;
        display:flex;
        flex-direction: column;
        align-items:center;
        bottom: 5vw;
        right: 120px;
        color: $red;
        @include break-up(desktop){
            right: 460px;
        }
        h4{
            @include font-floating;
            font-weight: 500;
            color: $red;
        }
        svg{
            width: 40px;
            @include break-up(desktop){
                width: 50px;
            }
        }
    }
}

.section-newsletter{
    display: flex;
    flex-direction: column;
    background-color: $white;
    @include break-up(desktop){
        flex-direction: row;
    }
    >div{
        @include break-up(desktop){
            width: 70%;
        }
    }
    >form{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 1rem;
        @include break-up(desktop){
            width: 20%;
        }
        >div{
            width: 100%;
        }
    }
}