@import "/src/app/styles/for-components";

header{
    position: relative;
    height: $header-height;
    padding: 0 $layout-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $background;
    @include break-up(tablet){
    }
    .logo{
        height: 50%;
        @include break-up(tablet){
            height: 70%;
        }
        svg{
            height: 100%;
        }
    }
    .navigation{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding-top: $header-height;
        background-color: $background;
        box-shadow: $shadow;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
        @include break-down(tablet){
            &.activ{
                transform: translateX(0%);
                opacity: 1;
                visibility: visible;
            }
            &.inactiv{
                transform: translateX(-100%);
                opacity: 0;
                visibility: hidden;
            }
        }
        @include break-up(tablet){
            position: static;
            width: auto;
            height: 100%;
            padding-top: 0;
            box-shadow: inherit;
            display: flex;
            align-items: center;
            gap: 1rem;
            opacity: 1;
            visibility: visible;
            transform: inherit;
        }
        >ul{
            display: flex;
            align-items: center;
            flex-direction: column;
            text-transform: uppercase;
            $link-padding: 2rem;
            letter-spacing: 1.1px;
            @include break-down(small-mobile){
                $link-padding: 1rem;
            }
            @include break-up(tablet){
                flex-direction: row;
            }
            >li{
                position: relative;
                width: 100%;
                @include break-up(tablet){
                    width: auto;
                }
                @include break-down(tablet){
                    >a,>span{
                        font-size: 1.5rem;
                    }
                }
                >a{
                    display: block;
                    padding: $link-padding;
                    @include break-up(tablet){
                        text-align: center;
                        &.active:before{
                            content: url("../../../assets/svg/trait-rouge-grand.svg");
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            min-width: 100%;
                            height: auto;
                        }
                    }
                    &:hover{
                        color: $red;
                    }
                }
            }
            .subnav{
                >span{
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding: $link-padding;
                    cursor: pointer;
                    svg{
                        width: 12px;
                        transition: transform 0.25s ease;
                    }
                    &:hover{
                        color: $red;
                        path{
                            fill: $red;
                        }
                    }
                    @include break-up(desktop){
                        text-align: center;
                        &.active:before{
                            content: url("../../../assets/svg/trait-rouge-grand.svg");
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            min-width: 100%;
                            height: 100%;
                        }
                    }
                }
                >ul{
                    position: relative;
                    background-color: $white;
                    @include break-up(tablet){
                        position: absolute;
                    }
                    >li>a{
                        display: block;
                        padding: 1rem 2rem;
                        white-space: nowrap;
                        &:hover{
                            background-color: $black;
                            color: $white;
                        }
                    }
                }
            }
        }
        >div{
            display: flex;
            gap: 1rem;
            @include break-down(tablet){
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-30%);
                display: flex;
                align-items: center;
                gap: 1.5rem;
                height: $header-height;
            }
        }
    }
    .burger{
        stroke: $black;
        cursor: pointer;
        height: 100%;
        width: $header-height;
        @include break-up(tablet){
            display: none;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }
}

.profil{
    position: relative;
    cursor: pointer;
    &.isLogged span{
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $green;
        border-radius: 50px;
        top: -3px;
        right: -3px;
    }
    & .userMenu{
        opacity: 0;
        visibility: hidden;
        width: auto;
        padding-top: 37px;
        top: 0;
        position: absolute;
        left: 50%;
        transform: translateY(0%) translateX(-50%);
        text-align: center;
        ul{
            background-color: $white;
        }
        li a {
            display: block;
            white-space: nowrap;
            padding: 0.5rem 1rem;
            &:hover{
                background: $black;
                color: $white;
            }
        }
    }
    &:hover .userMenu{
        opacity: 1;
        visibility: visible;
    }
}

.basket{
    position: relative;
}

/*___LANGUAGE LIST___*/

.current-language{
    position: relative;
    cursor: pointer;
    span{
        text-transform: uppercase;
        padding: 1rem;
    }
    svg{
        position:absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        transition: transform 0.25s ease;
    }
}

.language a{
    display: block;
    width: 100%;
    padding: 1rem;
    padding-left: 0.82rem;
    text-transform: uppercase;
    @include break-up(desktop){
        padding: 1rem;
        &:hover{
            background-color: $black;
            color: $white;
        }
    }

}

.language-list{
    width: 100%;
    position: absolute;
    top: 60%;
    left: 0;
    margin-top: 0.5rem;
    background-color: $white;
    text-transform: uppercase;
    @include break-up(desktop){
        top: calc(100% + 1rem);
        left: 50%;
        transform: translateX(-50%);
        margin-top: 0;
    }
}
