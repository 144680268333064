@import "/src/app/styles/for-components";

footer.main-footer{
    padding: 0 $layout-padding;
    padding-top: 3rem;
    background-color: $black;
    @include break-up(desktop){
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-template-rows: repeat(3, auto);
        padding-top: 6rem;
    }
    div{
        color: $white;
    }
    address{
        color: $white;
        .logo,a{
            display: block;
        }
        >svg{
            width: 60%;
            margin-bottom: 2rem;
            max-width: 250px;
        }
        .footer-icone path{
            fill: $white;
        }
    }
    .links{
        @include break-up(desktop){
            display: grid;
            grid-template-rows: 70% 30%;
            gap: 0;
        }
        ul{
            color: $white;
            margin-top: 1rem;
            @include break-up(desktop){
                margin-top: 0;
            }
            a{
                display: block;
                margin: 0.25rem 0;
                color: $white-transparent;
                &:hover{
                    text-decoration: underline;
                    color: $white;
                }
            }
        }
    }
    .social-network{
        @include break-down(desktop){
            margin-top: 1rem;
        }
        .social-network-icone{
            path{
                fill: $white;
            }
            &:hover{
                background-color: $white;
                border-color: $red;
                path{
                    fill: $red;
                }
            }
        }
    }
    div:nth-child(5){
        grid-column: 1/5;
        display: flex;
        gap: 2rem;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 4rem;
        @include break-up(desktop){
            flex-direction: row;
            gap: 0;
        }
        div{
            >svg,img{
                margin-bottom: 2rem;
                margin-right: 2rem;
                @include break-up(desktop){
                    margin-bottom: 0;
                    margin-right: 3rem;
                }
            }
            img{
                width: 180px;
            }
            svg{
                width: 150px;
            }
        }
        a{
            display: flex;
            align-items: center;
            gap: 0.5rem;
            height: fit-content;
            width: fit-content;
            padding: 0.5rem 1rem;
            color: $white;
            border: 1px solid $white;
            border-radius: 8px;
            cursor: pointer;
            &:hover{
                background-color: $red;
            }
        }
    }
    div:nth-child(6){
        grid-column: 1/5;
        display: flex;
        justify-content: space-between;
        padding: 1.325rem 0;
        flex-direction: column;
        align-items: center;
        gap: .625rem;
        text-align: center;
        span {
          color: $white-transparent;
          line-height: 1.325rem;
          &, a, { font-size: .875rem; }
          a, &.linkable {
            color: $white;
            font-weight: 700;
            cursor: pointer;
            &:hover {
              color: $white;
              text-decoration: underline;
            }
          }
          &.copyright, &.copyright a { font-size: .75rem; }
        }
    }
}
