@import "../../styles/for-components";

/*CONTACT FORM*/

.contact-form{
    width: 100%;
    margin: auto;
    @include break-up(desktop){
        display: grid;
        grid: repeat(4,auto) 3.5rem/repeat(4,1fr);
        column-gap: 1.5rem;
        row-gap: 1.5rem;
        & .input-block:nth-child(1){
            grid-row: 1/1;
            grid-column: 1/3;
        }
        & .input-block:nth-child(2){
            grid-row: 1/1;
            grid-column: 3/5;
        }
        & .input-block:nth-child(3){
            grid-row: 2/3;
            grid-column: 1/3;
        }
        & .input-block:nth-child(4){
            grid-row: 2/3;
            grid-column: 3/5;
        }
        & .input-block:nth-child(5){
            grid-row: 3/4;
            grid-column: 1/5;
        }
        & .input-block:nth-child(6){
            grid-row: 4/5;
            grid-column: 1/5;
        }
    }
    & .form-button{
        margin: auto;
        margin-top: 3rem;
        @include break-up(desktop){
            grid-row: 4/5;
            grid-column: 4/5;
            margin: 0;
            margin-top: auto;
            width: 80%;
            justify-content: center;
            justify-self: flex-end;
            align-self: center;
        }
    }
}

.input-block{
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 1rem;
    @include break-up(desktop){
        margin-bottom: 0;
        & input{
            width: 100%;
        }
    }
    input{
        width: 100%;
    }
    .contactType{
        & label{
            display: flex;
            gap: 0.5rem;
            align-items: center;
            margin-right: 2rem;
            font-size: 1rem;
        }
    }
    .contactType p {
        width: 100%;
        margin-bottom: 1rem;
    }
    label{
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
    }
    >span:not(.slider) {
        font-size: 0.7rem;
        color: red;
        max-width: 100%;
        top: 100%;
        @include break-up(desktop){
            position: absolute;
        }
    }
    &.subject{
        select:focus + svg{
            transform: rotate(180deg);
        }
        svg {
            position: absolute;
            height: 16px;
            right: 1rem;
            top: 38px;
            z-index: 1;
            @include break-up(desktop){
                top: 44px;
            }
        }
    }
}

.legalMention{
    grid-row: 5 / 6;
    grid-column: 1 / 5;
    a{
        display: block;
        margin: 0.5rem auto;
        &:first-letter { text-transform: uppercase };
        &:hover{
            color: $red;
            text-decoration: underline;
        }
    }
}

.profile-form{
    margin: 4rem 0;
    >div:nth-of-type(1){
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 1280px;
        width: 95%;
        margin: auto;
        margin-bottom: 4rem;
        padding: 2rem 1rem 1rem 1rem;
        background-color: $white;
        border-radius: $border-big;
        box-shadow: $shadow;
        @include break-up(desktop){
            width: auto;
            flex-flow: row wrap;
            justify-content: space-between;
            padding: 2rem $layout-padding;
        }
        >div{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: 1rem;
            @include break-up(desktop){
                width: 45%;
                gap: 1.5rem;
            }
        }
        h3{
            width: 100%;
            margin-bottom: 2rem;
        }
        .input-block{
            width: 100%;
            &.street{
                width: 68%;
                @include break-up(desktop){
                    width: 75%;
                }
            }
            &.streetNbr{
                width: 25%;
                @include break-up(desktop){
                    width: 19%;
                }
            }
            &.npa{
                width: 23%;
            }
            &.city{
                width: 70%;
            }
        }
    }
    .connexion-form-buttons{
       justify-content: flex-end;
       width: 100%;
       margin-top: 2rem;
        @include break-down(desktop){
            button, a{
                width: 100%;
            }
        }
    }
}
