@import "../../styles/for-components";

.icone{
    &:hover path{
        fill: $red;
    }
}
.social-network-icone{
    display:flex;
    justify-content:center;
    align-items:center;
    width: 40px;
    height: 40px;
    border: 1px solid currentColor;
    border-radius: 100px;
    path{
        fill: currentColor;
    }
}
.cartQuantity{
    position: absolute;
    top: -0.5rem;
    right: -1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 5px;
    background-color: $white;
    color: $red;
    font-weight: 500;
}