@import "/src/app/styles/for-components";

@keyframes traitShow {
  to { visibility: visible; }
}

.btn, .has-handdrawn {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  width: fit-content;
  font-weight: 800;
  cursor: pointer;

  &.activ { color: $red; }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -.5rem;
    width: 100%;
    aspect-ratio: 124.37 / 6.43;
    mask: url("../../../assets/svg/trait.svg");
    -webkit-mask: url("../../../assets/svg/trait.svg");
    background: $red;
    clip-path: inset(0 100% 0 0);
    transition: clip-path .25s ease-in-out;
  }

  &:hover {
    color: $red;
    text-decoration: none;
    &:before { clip-path: inset(0 0 0 0); }
  }
}

.has-handdrawn, .btn.with-arrow {
  &:after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    mask: url("../../../assets/svg/button-caret.svg");
    -webkit-mask: url("../../../assets/svg/button-caret.svg");
    margin-top: 3px;
    background: $black;
    transition: transform .25s ease;
  }

  &:hover:after {
    background: $red;
    transform: translateX(5px);
  }
}

.btn-red, .has-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  min-height: 48px;
  height: fit-content;
  border-radius: 8px;
  border: 1px solid $red;
  background: $red;
  padding: .5rem 1rem;
  font-weight: 500;
  color: $white;
  cursor: pointer;
  svg { fill: $white; }

  &.uppercase { text-transform: uppercase; }

  &:hover {
    background: $white;
    color: $red;
    svg { fill: $red; }
  }

  &:disabled {
    background: $light-grey;
    border-color: $light-grey;
  }
}

.btn-red.inverted {
  background: $white;
  color: $red;
  svg { fill: $red; }

  &:hover {
    background: $red;
    color: $white;
    svg { fill: $white; }
  }
}
