@import "/src/app/styles/for-components";

/*NBR PERSON*/


.nbrGuests{
    .nbrGuests-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 3.5rem;
        gap: 1rem;
        .counter{
            display: flex;
            align-items: center;
            gap: 0.5rem;
            button{
                display:flex;
                justify-content:center;
                align-items:center;
                width: 45px;
                height: 30px;
                cursor: pointer;
                &:not(.nbrGuests button:disabled):hover{
                    border-color: $red;
                }
                &:not(.nbrGuests button:disabled):active{
                    background-color: $red;
                    color: $white;
                }
            }
            span{
                min-width: 12px;
            }
    
        }
        p{
            display: block;
            font-size: clamp(14px, 1rem, 20px);
            @include break-up(desktop){
                font-size: initial;
            }
            &  span{
                display: block;
                width: 100%;
                font-size: clamp(12px, 0.8vw, 14px);
                line-height: 1;
                opacity: 0.7;
                text-transform: lowercase;
                @include break-up(desktop){
                    font-size: 12px;
                }
            }
        }

    }
}

