@import "/src/app/styles/for-components";

.card-discount{
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    border-radius: $border-small;
    background-color: $green;
    color: $white;
    z-index: 1;
    cursor: pointer;
    & svg{
        animation: Bounce 2s infinite ease-in-out;
    }
    & div{
        position: absolute;
        display: flex;
        width: max-content;
        left: 110%;
        padding: 0.5rem;
        background: $green;
        border-radius: $border-small;
        opacity:0;
        visibility: hidden;
    }
    &:hover div{
        opacity:1;
        visibility: visible;
    }
}

@keyframes Bounce {
    0%{
        transform: scale(1.2);
    }
    50%{
        transform: scale(1.4);
    }
    100%{
        transform: scale(1.2);
    }
}