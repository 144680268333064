@import "/src/app/styles/for-components";

.payment{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1280px;
    width: 95%;
    margin: auto;
    margin-bottom: 4rem;
    padding: 2rem 1rem 1rem 1rem;
    background-color: $white;
    border-radius: $border-big;
    box-shadow: $shadow;
    @include break-up(desktop){
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 0;
        width: auto;
        padding: 2rem $layout-padding;
    }
    >div{
        width: 100%;
        margin: 0;
        @include break-up(desktop){
            width: 45%;
        }
        p:last-child{
            font-size: 0.85rem;
        }
        span{
            white-space: nowrap;
        }
    }
    >div:nth-child(1){
        >div{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            padding: 1rem;
            cursor: pointer;
            >div{
                width: 100%;
                display:flex;
                justify-content:flex-end;
                align-items:center;
                gap: 0.5rem;
            }
            >div:first-child{
                font-weight: 500;

            }
            p{
                flex: 1 1 auto;
            }
        }
    }
    >div:nth-child(2){
        div{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            margin-bottom: 0.5rem;
          &:not(:last-child) { margin-bottom: 2rem; }
        }
        span{
            text-align: right;
            font-weight: 500;
        }
    }
    h3 {
        margin-bottom: 2rem;
    }
    span.radio{
        width: 15px;
        min-width: 15px;
        height: 15px;
        border-radius: 50px;
        border: $border;
    }
    div.selected {
        background-color: $red;
        color: $white;
        border-radius: $border-small;
        span.radio{
            width: 15px;
            min-width: 15px;
            height: 15px;
            border-radius: 50px;
            border: 4px solid $white;
        }
    }
    .payment-buttons {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        @include break-down(desktop){
            button, a{
                width: 100%;
            }
        }
        @include break-up(desktop){
            gap: 2rem;
            margin-top: 2rem;
        }
    }
    .skeleton{
        margin-top: 2rem;
    }
}
