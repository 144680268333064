@import "../../styles/for-components";

.articlesCarousel{
    width: 100%;
    margin: auto;
    max-width: 1190px;
    @include break-up(desktop){
        width: 70%;
    }
    @include break-up(big-desktop){
        width: 90%;
    }
    .carousel-arrow{
        width: 4vw;
        height: 4vw;
        max-width: 65px;
        max-height: 65px;
        background: none;
        opacity: 1;
        transition: transform 0.25s ease;
        &:hover{
            transform: translateY(-50%) scale(1.1);
            transition: transform 0.25s ease;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }
    .splide__arrow--next{
        right: -5vw;
    }
    .splide__arrow--prev{
        left: -5vw;
    }
}