@import "/src/app/styles/for-components";

.contact{
    @include break-down(desktop){
        padding-bottom: 15rem;
    }
    .social-network{
        margin-top: 1rem;
    }
    .social-network-icone,address svg{
        color: $red;
        path{
            fill: $red;
        }
    }
    .social-network-icone:hover{
        background-color: $white;
    }
    .contact-form{
        @include break-down(desktop){
            margin-top: 2rem;
        }
    }
    img{
        position: absolute;
        bottom: 0;
        right: 5rem;
    }
    .floating-img{
        position: absolute;
        bottom: -60px;
        right: 10px;
        height: 80vw;
        max-height: 600px;
        @include break-up(desktop){
            right: 1vw;
            bottom: -60px;
            max-height: 400px;
            height: 30vw;
        }
        @include break-up(big-screen){
            right: 5vw;
            bottom: -60px;
            max-height: 600px;
        }
    }
    .floating-text{
        position: absolute;
        display:flex;
        flex-direction: column;
        align-items:center;
        bottom: 30px;
        left: 90px;
        color: $red;
        @include break-up(mobile){
            left: inherit;
            right: 180px;
        }
        @include break-up(desktop){
            right: 18vw;
        }
        h4{
            @include font-floating;
            font-weight: 500;
            color: $red;
        }
        svg{
            width: 40px;
            @include break-up(desktop){
                width: 50px;
            }
        }
    }
}